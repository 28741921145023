.top-wrapper {
    color: white;
    position: relative;
    padding-bottom: 2rem;
    z-index: 1;
    

}

.top-container {
    justify-content: space-around;
    align-items: flex-end;
}

/* Right Side  */

.image-container {
    width: 30rem;
    height: 35rem;
    overflow: hidden;
    border-radius: 10rem 0 0 0;
    border: 8px solid rgba(255, 255, 255, 0.12);

}

.image-container>img {
    width: 100%;
    height: 100%;
}

.top-left {
    gap: 3rem
}

.t-title {
    position: relative;
    z-index: 1;
}

.t-title>h1 {
    font-weight: 600;
    font-size: 3.5rem;
    line-height: 4rem;
}

.orange-circle {
    height: 4rem;
    width: 4rem;
    background: var(--red-gradient);
    position: absolute;
    right: 10%;
    z-index: -1;
    top: -12%
}

.search-bar {
    background-color: white;
    border-radius: 5px;
    border: 3px solid rgba(120, 120, 120, 0.374);
    padding: 0.5rem 1rem;
    justify-content: space-between;
    width: 100%;
}

.stats {
    width: 90%;
    justify-content: space-between;
}

.stat>:nth-child(1) {
    font-size: 2rem;
}

.stat>:nth-child(1)>:last-child {
    color: red;
}

.second-text {
    color: red;
    font-size: 0.9rem;
}


.search-bar>input {
    border: none;
    outline: none;
}

@media (max-width:640px) {
    .t-title>h1 {
        font-weight: 300;
        font-size: 1.5rem;
        line-height: 2rem;
    }

    .top-container {
        margin-top: 2rem;
    }

    .image-container {
        width: 95%;
        height: 25rem;
    }

    .stats {
        gap: 1.5rem;
        justify-content: center;
    }

    .top-right {
        width: 100%;
    }

    .stat>:nth-child(1) {
        font-size: 1.5rem;
    }

    .stat>:nth-child(2) {
        font-size: 0.8rem;
    }

}