.image2-container{
    width: 30rem;
    height: 20rem;
    overflow: hidden;
    border-radius: 2rem 2rem 0 0 ;
    border: 1px solid rgba(14, 7, 7, 0.05);
    
}

.v-container .image2-container{
    border: 4px solid rgb(232 232 232 / 93%);
}

.v-container>div{
    flex: 1;
}

.v-right{
    gap:0.5rem
}

.accordionItem{
    background: white;
    border: 0.8px solid rgba(128,128,128,0.143);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
   
}

.accordion{
    border: none;
}

.accordionbutton{
    background: white;
    padding: 1rem;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
}

.icon{
    padding: 10px;
    background: #eeeeff;
    border-radius: 5px;
}

.icon svg{
    fill:var(--blue)
}

.accordionbutton .primary-text{
    font-size: 1.1rem;
}

.accordionItem.expanded{
    box-shadow: var(--blue);
    border-radius: 6px;
}

@media (max-width:1024px){

    .v-container{
        flex-direction: column;
    }

    @media (max-width :768px){
        .accordionbutton .primary-text{
            font-size: 0.8rem;
        }

    }

}