

.h-container2{
    display: flex; /* This makes insider of the parent container display HORIZONTALLY */
    align-items: center;/* This makes insider of the parent container display ITEM CENTRALLY */ 
    justify-content: center;/* This makes insider of the parent container display ITEM CENTRALLY of Display */ 
}
.h-wrapper{
    
    color: var(--white);
   
}

.h-container{
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: white;
}

.h-menu{
    gap:2rem;
    color: red;
    
}

.i-menu{
    color: white;
}

.menu-icon{
    display: none;
}

@media (max-width:768px) {
    .menu-icon{
        display: block;
    }

    .h-menu{
        color: var(--black);
        position: absolute;
        top: 3rem;
        right: 4rem;
        background: white;
        flex-direction: column;
        font-weight: 500;
        gap: 2rem;
        padding: 3rem;
        border-radius: 10px;
        align-items: flex-start;
        box-shadow: 0px 4px 4px rgba(0,0,0,0.05);
        transition: all 300ms ease-in;
        z-index: 99;
    }

    .i-menu{
        color: black;
    }
    
}