.inner-container{
    gap: 1.5rem;
    background: #4161df;
    padding: 2rem;
    border-radius: 10px;
    border: 6px solid #5d77d6;
    text-align: center;
}

/* .f-wrapper{
    background: red;
} */

.inner-container .button{
    border: 2px solid white;
    box-shadow: greenyellow;
    border-radius: 10px;
}