body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --black: #131110;
  --blue: #4066ff;
  --white: #FFFFFF;
  --blue-gradient: #7879FF;
  --red-gradient:#DC1C13;



}
.red-text{
  color: red;
  font-size: 1.5rem;
  font-weight: 600;
}
.primary-text{
  color: rgb(6, 6, 6);
  font-weight: bold;
  font-size: 2rem;
}

.button{
  font-weight: 500;
  padding: 0.6rem 1.4rem;
  color: white;
  background: red;
  border: none;
  border-radius: 4px;
  transition: all 300ms ease-in ;
}

.button:hover{
  cursor: pointer;
  transform: scale(1.1);
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}


.flexColStart{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.flexColCenter{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.paddings {
  padding: 1.5rem;
}

.innerWidth {
  width: 100%
}

.flexCenter {
  display: flex;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.second-text{
  color: red;
  font-size: 0.9rem;
}



/* media quries */

@media (min-width:1536px) {
  .innerWidth {
    max-width: 1400px;
    margin: auto;
  }
}

@media (min-width:640px) {
  .paddings {
    padding: 4rem;
  }
}